/* eslint no-param-reassign: ["error", { "props": false }] */

let toast_title_success = "Success!";
let toast_title_failure = "Failure!";
let toast_text_add_success = "Added '{}' to your favourites.";
let toast_text_add_failure = "Could not add '{}' to your favourites.";
let toast_text_remove_success = "Removed '{}' from your favourites.";
let toast_text_remove_failure = "Could not remove '{}' from your favourites.";

/**
 * Configures which strings should be used for the toast messages, for translations
 * @param {String} titleSuccess
 * @param {String} titleFailure
 * @param {String} textAddSuccess
 * @param {String} textAddFailure
 * @param {String} textRemoveSuccess
 * @param {String} textRemoveFailure
 */
export function configure(
    titleSuccess,
    titleFailure,
    textAddSuccess,
    textAddFailure,
    textRemoveSuccess,
    textRemoveFailure
) {
    toast_title_success = titleSuccess;
    toast_title_failure = titleFailure;
    toast_text_add_success = textAddSuccess;
    toast_text_add_failure = textAddFailure;
    toast_text_remove_success = textRemoveSuccess;
    toast_text_remove_failure = textRemoveFailure;
}

/**
 * Toggles the given game's favourite status.
 *
 * Also issues a toast message, communicating to the player what action was performed.
 * @param {HTMLInputElement} inputElement
 * @param {String} gameUuid
 * @param {String} gameDisplayName
 * @returns {Promise<void>}
 */
export async function toggle(inputElement, gameUuid, gameDisplayName) {
    // Return if the input element is disabled, such as if a previous request is still being processed
    if (inputElement.disabled) return;

    // Get the authorization meta-element which contains the user's authorization token
    // This should be done on the parent window, in order to support this window being rendered through an iframe
    const meta = parent.document.querySelector('meta[http-equiv=Authorization]');

    // Return if the authorization meta-element is absent or empty
    if (meta == null || meta.content == null || meta.content.length === 0) return;

    const newState = !inputElement.checked;

    let toast_title = '';
    let toast_text = '';

    try {
        // Disable the input element until the request has been completed
        inputElement.disabled = true;

        // Issue a request to mark/unmark the given game as a favourite
        // Uses the state of the input element to determine which HTTP method to use
        const response = await fetch(`/fragment/gamefilter/ajax/favourites/${gameUuid}`, {
            method: newState ? 'POST' : 'DELETE',
            headers: { Authorization: `Bearer ${meta.content}` },
        });

        if (response.ok) {
            toast_title = toast_title_success;
            toast_text = newState ? toast_text_add_success : toast_text_remove_success;

            // Update the associated input element's state
            inputElement.checked = newState;

            const selectors = 'input[name="gff-game-' + gameUuid + '"]';

            // Cascade the update to all other instances of the same game
            document.querySelectorAll(selectors).forEach(el => el.checked = newState);

            // Cascade the update to the parent window, for example if this window is rendered through an iframe
            if (window !== parent) {
                parent.document.querySelectorAll(selectors).forEach(el => el.checked = newState);
            }
        }
        else {
            toast_title = toast_title_failure;
            toast_text = newState ? toast_text_add_failure : toast_text_remove_failure;
        }
    } finally {
        // Enable the input element when the request has been completed
        inputElement.disabled = false;

        issueToastMessage(toast_title, toast_text.replace('{}', gameDisplayName.trim()));
    }
}

/**
 * Issues a toast message with the given title and text
 * @param toastTitle
 * @param toastText
 */
function issueToastMessage(toastTitle, toastText) {
    parent.postMessage({ "channel": "INSTANT_TOAST", "title": toastTitle, "text": toastText }, parent.origin);
}

window.GameFavourites = {
    configure,
    toggle,
};
